import { Table, Switch, Radio, Form, Space, Popconfirm, Button, Modal, Tag, Typography, Image, Input, Row } from 'antd';
import React, { useRef, useState, useEffect, useCallback } from 'react'
//import PageTitle from "../../components/Page-Title/index";
import axios from "axios";
import IframeResizer from 'iframe-resizer-react'
import { offlineUrlExpress } from "../../urls";
import styled from "@emotion/styled";
import moment from 'moment';
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from "react-zoom-pan-pinch";
import {
  LeftCircleFilled,
  RightCircleFilled,
  ArrowsAltOutlined,
  FormOutlined,
  CloseCircleOutlined,
  RightOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import TestResultVirtualDisplay from '../TestResult-Virtual-Display';
import { ConditionType, DatasetDetail } from '../TestResult-Virtual-Display/index';
import VirtualImageComp, { ResultType } from '../TestResult-Virtual-Image';

export interface TasksValidTestProps {
  taskstitle: string;
  taskstype: string;
};
const init_dataset = {
  datasetId: "",
  taskId: "",
  datasetType: "",
};
/*
action: "train"
create_at: "2021-06-14T08:05:23.944Z"
id: 4
name: "Detect Train"
status: "Queue"
taskid: "0fddf740-42fc-4af5-858c-a840432eb67e"
type: "DETECT"
*/

const ModalContainer = styled.div<{ isModalOpen: boolean, isModalHide: boolean }>`
  top: 0;
  right: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: ${({ isModalHide }) => (isModalHide ? "-999" : "10")};
  background: white;
  display: ${({ isModalOpen }) => (isModalOpen ? "flex" : "none")};
  padding: 2rem 0;
  background-color: whitesmoke;
`;

const ModalDisplayContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  overflow: auto;
  flex-direction: column;
  -justify-content: space-between;
`;

const ModalDisplayImageContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  overflow: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
`;

const MyTag = styled(Tag)`
  margin-bottom: 2px;
  :hover {
    color: darkblue;
  }
`;

const { Title } = Typography;

const GroupDiv = styled.div`
  margin-right: 2rem;
  margin-left: 2rem;
`;

const InnerContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  @media (min-width: 768px) {
    padding-top: 0.6rem;
  }  
}
`;
const ImageDom = () => {
  return
};
const InnerDiv = styled.div``;

const TasksValidTest: React.FC<TasksValidTestProps> = ({ taskstitle, taskstype }) => {

  const [hasData, sethasData] = useState(false);
  const [dataset, setDataset] = useState([]);
  const [filterDataset, setFilterDataset] = useState(dataset);
  const [getFilterOption, setFilterOption] = useState("User");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalTrigger, setModalTrigger] = useState(false);
  const [getResultUrl, setResultUrl] = useState("");
  const { currentUser } = useTypedSelector((state) => state.auth);
  const [getChangeStatus, setChangeStatus] = useState(false);
  const [getSelectRecord, setSelectRecord] = useState({});


  const [searchText, setSearchText] = useState('');

  //my test state
  const [currentDataset, setCurrentDataset]
    = useState<{ datasetId: string, taskId: string, datasetType: string}>(init_dataset);
  const [datasetLogUrl, setDatasetLogUrl]
    = useState<string | null>(null);
  const [virtualModalVisible, setVirtualModalVisible]
    = useState<boolean>(false);
  const [onVirtual, setOnVirtual]
    = useState<boolean>(false);

  const [isImageModalVisible, setIsImageModalVisible]
    = useState<boolean>(false);
  const [isImageModalHide, setIsImageModalHide]
    = useState<boolean>(true);
  const [isModalHide, setIsModalHide]
    = useState<boolean>(false);
  const [preImage, setPreImage]
    = useState<{ open: boolean, url: string, results: ResultType[] | null, condition: ConditionType | null }>({
      open: false, url: '', results: null, condition: null
    });
  const [imageCount, setImageCount]
    = useState<{ index: number, total: number } | null>(null);
  //console.log(currentUser);
  const iframeRef: any = useRef(null)
  const refZoom = useRef<ReactZoomPanPinchRef | null>(null);

  const {
    currentIndex,

    isOnGoingLabelModelOpen,

  } = useTypedSelector((state) => state.onGoinglabel);

  const {
    datasetImageNavigateTo,
    setOnGoingLabelDataset,
    setOnGoingLabelDatasetNavigateTo,
  } = useActions();

  const handleSearch = (value: any) => {
    setSearchText(value);
  };

  const columns: any = [
    /*
    {
      title: 'TaskID',
      dataIndex: 'taskid',
      width: 200
    }, {
      title: 'Apply to ModelID',
      dataIndex: 'parenttaskid'
    }, 
    */
    {
      title: 'Model Name',
      dataIndex: 'modelname',
      sorter: (a: any, b: any) => {
        a = a.modelname || '';
        b = b.modelname || '';
        return a.localeCompare(b);
      }
    },
    {
      title: 'Dataset Name',
      dataIndex: 'datasetname',
      sorter: (a: any, b: any) => {
        a = a.datasetname || '';
        b = b.datasetname || '';
        return a.localeCompare(b);
      },
      render: (_: any, record: any) => {
        return (
          <Space size="middle">
            <a>{record.datasetname}</a>
          </Space>
        )
      },
      onCell: (record: any, rowIndex: any) => {
        return {
          onClick: async (ev: any) => {
            //console.log(record, rowIndex);

            const dataset = await axios.get(
              `${offlineUrlExpress}/api/dataset/${record.datasetid}`
            );

            if (dataset.data.length > 0) {
              setOnGoingLabelDatasetNavigateTo(dataset.data[0].id, dataset.data[0].type, dataset.data[0].name, dataset.data[0].classifications, 0);
            }

            //setOnGoingLabelDatasetNavigateTo(record.datasetid, record.type, record.datasetname, [], 0);
          },
        };
      },

    }, {
      title: 'Type',
      dataIndex: 'type',
      sorter: (a: any, b: any) => {
        a = a.type || '';
        b = b.type || '';
        return a.localeCompare(b);
      }
    }, {
      title: 'Action',
      dataIndex: 'action',
      filters: [
        {
          text: 'train',
          value: 'train',
        },
        {
          text: 'valid',
          value: 'valid',
        },
        {
          text: 'test',
          value: 'test',
        }
      ],
      onFilter: (value: any, record: any) => record.action.indexOf(value) === 0,
      sorter: (a: any, b: any) => {
        a = a.action || '';
        b = b.action || '';
        return a.localeCompare(b);
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 150,
      render: (status: string) => {
        let tColor = 'magenta';
        switch (status) {
          case "Done":
            tColor = 'green';
            break;
          case "Queue":
            tColor = 'cyan';
            break;
          case "Processing":
            tColor = 'volcano';
            break;
          case "Training":
            tColor = 'orange';
            break;
          case "Validating":
            tColor = 'gold';
            break;
        }

        return (
          <>
            <MyTag color={tColor}>{status}</MyTag>
          </>
        );
      },


      filters: [
        {
          text: 'Queue',
          value: 'Queue',
        },
        {
          text: 'Done',
          value: 'Done',
        },
        {
          text: 'Processing',
          value: 'Processing',
        },
        {
          text: 'Training',
          value: 'Training',
        },
        {
          text: 'Validating',
          value: 'Validating',
        }
      ],
      onFilter: (value: any, record: any) => record.status.indexOf(value) === 0,
      sorter: (a: any, b: any) => {
        a = a.status || '';
        b = b.status || '';
        return a.localeCompare(b);
      }
    },
    {
      title: 'Create By',
      dataIndex: 'username',
      sorter: (a: any, b: any) => {
        a = a.username || '';
        b = b.username || '';
        return a.localeCompare(b);
      }
    },
    {
      title: 'Create Time',
      dataIndex: 'create_at',
      render: (create_at: string) => {
        let sdate = moment(create_at).format("YYYY/MM/DD HH:mm:ss");
        return (
          <div>{sdate}</div>
        );
      },
      sorter: (a: any, b: any) => {
        a = a.create_at || '';
        b = b.create_at || '';
        return a.localeCompare(b);
      }
    },
    {
      title: 'Last Update Time',
      dataIndex: 'lastupdate',
      render: (lastupdate: string) => {
        let sdate = "";
        if (lastupdate != null) {
          sdate = moment(lastupdate).format("YYYY/MM/DD HH:mm:ss");
        }
        return (
          <div>{sdate}</div>
        );
      },
      sorter: (a: any, b: any) => {
        a = a.lastupdate || '';
        b = b.lastupdate || '';
        return a.localeCompare(b);
      }
    },
    {
      title: 'Operation',
      width: 150,
      dataIndex: '',
      key: 'x',
      render: (record: any) => (
        <Space size="middle">
          <MyTag color="geekblue" onClick={() => { handleDetail(record) }}>Detail</MyTag>
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
            <MyTag color="magenta">Delete</MyTag>
          </Popconfirm>
        </Space>
      ),
      /*
    render: () => (
        <Space size="middle">
        <a>Detail</a>
        <a>Delete</a>
        </Space>
    )
    */
    }
  ];

  const handleDelete = async (sdata: any) => {
    //const dataSource = [...dataset];

    let deleteTaskId = sdata.taskid;

    axios.post(`${offlineUrlExpress}/api/deletetask/` + deleteTaskId)
      .then(res => {
        console.log(res);
        console.log(res.data);
        sethasData(false);
        setChangeStatus(false);
        fetchGetTasksList();
      })
    //setDataset(dataSource.filter(item => item.taskid !== taskid));
  };

  const handleDetail = (sdata: any) => {
    //const dataSource = [...dataset];
    //console.log(sdata);
    showModal(sdata);
    //setDataset(dataSource.filter(item => item.taskid !== taskid));
  };

  const handleGoLabel = (sdata: any) => {
    setSelectRecord(sdata);
    let id = sdata['datasetid'];
    let type = sdata['type'];
    let name = sdata['datasetname'];
    let classifications: any = [];
    setOnGoingLabelDataset(id, type, name, classifications);
  };


  const handleChangePreImage = (direction: "right" | "left") => {
    if (imageCount) {
      let { index, total } = imageCount;
      if (direction === "right" && index < total) {
        setImageCount({ ...imageCount, index: index + 1 });
      };
      if (direction === "left" && index > 0) {
        setImageCount({ ...imageCount, index: index - 1 });
      };
    }
  };

  const handleOpenLabel = () => {

    let id = 8;
    let type = 'DETECT_AND_CLASSIFY';
    let name = 'MIC DetectionClassification Train';
    let classifications = [
      {
        "id": 38,
        "cid": 1,
        "description": "A"
      },
      {
        "id": 39,
        "cid": 2,
        "description": "B"
      },
      {
        "id": 53,
        "cid": 3,
        "description": "C"
      }
    ];

    classifications = [];

    setOnGoingLabelDataset(id, type, name, classifications);
  };

  const handleOnMessage = async (messagedata: any) => {
    let modeltype = messagedata.message.split(',')[0];
    let datasetid = messagedata.message.split(',')[1];
    let imagename = messagedata.message.split(',')[2];

    if (modeltype == 0) {
      // setPreviewImage(imagename);
      setIsImageModalVisible(true);
    } else if (modeltype == 1) {
      console.log(datasetid, imagename);
      const dataset = await axios.get(
        `${offlineUrlExpress}/api/dataset/${datasetid}`
      );

      if (dataset.data.length > 0) {
        const labelindex = await axios.get(
          `${offlineUrlExpress}/api/datasetimagesindex/${datasetid}/${imagename}`
        );
        console.log(dataset.data);
        console.log(labelindex.data);
        setIsModalVisible(false);
        setOnGoingLabelDatasetNavigateTo(dataset.data[0].id, dataset.data[0].type, dataset.data[0].name, dataset.data[0].classifications, labelindex.data - 1);
        //setOnGoingLabelDataset(dataset.data[0].id, dataset.data[0].type, dataset.data[0].name, dataset.data[0].classifications);
        //datasetImageNavigateTo(labelindex.data - 1);
      }
    }
  };

  const handleGotoLabelFromVirtual = async (datasetUrl: string) => {
    const dataset_id = currentDataset.datasetId;
    const image_name = datasetUrl.split('/').slice(-1)[0];
    const dataset = await axios.get(
      `${offlineUrlExpress}/api/dataset/${dataset_id}`
    );
    console.log('img', dataset)
    if (dataset.data.length > 0) {
      const labelindex = await axios.get(
        `${offlineUrlExpress}/api/datasetimagesindex/${dataset_id}/${image_name}`
      );
      setVirtualModalVisible(false);

      setOnGoingLabelDatasetNavigateTo(dataset.data[0].id, dataset.data[0].type, dataset.data[0].name, dataset.data[0].classifications, labelindex.data - 1);
    }
  };

  useEffect(() => {
    //console.log(`!!! isOnGoingLabelModelOpen : ${isOnGoingLabelModelOpen}`);
    if (isOnGoingLabelModelOpen === false && isModalTrigger && taskstype === 'test') {
      setIsModalVisible(true);
    } else if (isOnGoingLabelModelOpen === false && onVirtual && taskstype === 'test') {
      setVirtualModalVisible(true);
    }
  }, [isOnGoingLabelModelOpen]);
  /* 
  const handleAddTrain = async() => {

    let postData = `{
        "name": "Detect Train",
        "type": "DETECT",
        "action": "train",
        "directory" : "",
        "params": {
          "epochs": 20,
          "model_backbone": "resnet50",
          "use_pretrained": true,
          "learning_rate": 0.001,
          "optimizer": "adam",
          "save_best_model": false,
          "data_aug": false,
          "image_size": 512,
          "batch_size": 4,
          "validation_size": 0.2,
          "load_model": false
        }
      }`;

    axios.post(`${offlineUrlExpress}/api/createmodel/1`,postData, {headers: { 'Content-Type': 'application/json' }})
      .then(res => {
        console.log(res);
        console.log(res.data);
        sethasData(false);
        fetchGetTasksList();
      })    
    //setDataset(dataSource.filter(item => item.taskid !== taskid));
  };

  const handleAddValid = async() => {

    let postData = `{
        "name": "Detect Validation",
        "type": "DETECT",
        "action": "valid",
        "directory" : "",
        "params": {
          "image_size": 512,
          "load_model": "Model/test.pth",
          "confidence": 0.5
        }
      }`;

    axios.post(`${offlineUrlExpress}/api/createmodel/2`,postData, {headers: { 'Content-Type': 'application/json' }},)
      .then(res => {
        console.log(res);
        console.log(res.data);
        sethasData(false);
        fetchGetTasksList();
      })    
    //setDataset(dataSource.filter(item => item.taskid !== taskid));
  };
  */

  const fetchGetTasksList = async () => {
    //const jwt = localStorage.getItem("jwt");
    try {

      let apiUrl = `${offlineUrlExpress}/api/`;

      if (taskstype === 'tasks') {
        apiUrl = `${offlineUrlExpress}/api/tasklist`;
      } else if (taskstype === 'valid') {
        apiUrl = `${offlineUrlExpress}/api/validlist`;
      } else if (taskstype === 'test') {
        apiUrl = `${offlineUrlExpress}/api/testlist`;
      };

      const { data } = await axios.get(apiUrl);

      if (JSON.stringify(dataset) !== JSON.stringify(data)) {
        console.log("Update tasksList datatable !!!");
        setDataset(data);
        console.log("Update tasksList datatable !!!");

        let filterDataset;

        if (getFilterOption === 'All') {
          filterDataset = data;
        } else if (getFilterOption === 'Other') {
          filterDataset = data.filter(
            ({ username }: any) => username !== currentUser
          );
        } else if (getFilterOption === 'User') {
          filterDataset = data.filter(
            ({ username }: any) => username === currentUser
          );
        }

        setFilterDataset(filterDataset);
      };

      sethasData(true);
    } catch (err) {
      //message.error(err.message);

      sethasData(true);
    }
  };
  const fetchTaskListCallback = useCallback(() => {
    return fetchGetTasksList();
  }, [dataset]);

  const showVirtual = (url: string) => {
    setDatasetLogUrl(url);
    setIsImageModalHide(true);
    setOnVirtual(true);
    setVirtualModalVisible(true);
  };

  const showModal = (sdata: any) => {
    let taskid = sdata.taskid;
    let datasetid = sdata.datasetid;
    let type = sdata.type;
    let fulltaskidUrl = `${offlineUrlExpress}/logs/${taskid}/log.txt`;

    if (sdata.action === "test") {
      setCurrentDataset({
        datasetId: datasetid,
        taskId: taskid,
        datasetType: type,
      });
      return showVirtual(fulltaskidUrl);
    };
    if (sdata.action === "train") {
      setResultUrl("ShowTrainStatus.html?taskidurl=" + fulltaskidUrl);
    } else {
      setResultUrl("ShowValidStatus.html?taskidurl=" + fulltaskidUrl);
    };
    setModalTrigger(true);
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleVirtualOff = () => {
    setCurrentDataset(init_dataset);
    setVirtualModalVisible(false);
    setOnVirtual(false);
  };

  const handleImageModalCancel = () => {
    setIsImageModalVisible(false);
    setIsModalHide(false);
    setPreImage({
      ...preImage,
      open: false
    })
  };

  const handleShow = () => {
    setIsModalVisible(true);
    setIsModalHide(false);
  };


  const filterUser = () => {

    let filterDataset = dataset.filter(
      ({ username }: any) => username === currentUser
    );

    setFilterOption('User');
    setFilterDataset(filterDataset);

    //console.log(filterDataset);
  };

  const filterOther = () => {

    let filterDataset = dataset.filter(
      ({ username }: any) => username !== currentUser
    );

    setFilterOption('Other');
    setFilterDataset(filterDataset);

    //console.log(filterDataset);
  };

  const filterAll = () => {

    let filterDataset = dataset;

    setFilterOption('All');
    setFilterDataset(filterDataset);

    //console.log(filterDataset);
  };


  const handlePreImageDom = (
    url: string, results: ResultType[], condition: ConditionType
  ) => {

    refZoom?.current?.resetTransform();
    // setPreviewImage(url);
    setPreImage({
      open: true,
      url: url,
      results: results,
      condition: condition
    });
    setIsImageModalHide(false);
    setIsImageModalVisible(true);
    setIsModalHide(true);
  };

  const replaceLastSlashWithHeat = (url:any) => {
    return url.replace(/\/([^/]*)$/, "/heat/$1");
  };

  useEffect(() => {
    if (!hasData) {
      fetchTaskListCallback();
    };
    const timer = setInterval(() => {
      fetchTaskListCallback();
    }, 1500);
    return () => clearInterval(timer);
  });

  return (
    <>
      <InnerContainer>
        <InnerDiv>
          <Title>{taskstitle}</Title>
        </InnerDiv>
        <GroupDiv>
          <Button
            shape="round"
            style={{ marginRight: "1px" }}
            onClick={() => { filterUser(); }}
            danger={getFilterOption === 'User'}
          >
            USER
          </Button>
          <Button
            shape="round"
            style={{ marginRight: "1px" }}
            onClick={() => { filterOther(); }}
            danger={getFilterOption === 'Other'}
          >
            OTHER
          </Button>
          <Button
            shape="round"
            style={{ marginRight: "1px" }}
            onClick={() => { filterAll(); }}
            danger={getFilterOption === 'All'}
          >
            ALL
          </Button>
        </GroupDiv>
      </InnerContainer>

      <Row justify="end">
        <Space size="middle">
          {/* <MyTag color="geekblue" onClick={(e) => { handleAddTrain(); }}>Add Train Task</MyTag>
      <MyTag color="geekblue" onClick={(e) => { handleAddValid(); }}>Add Validate Task</MyTag> */}
          <MyTag color="gold" onClick={() => { fetchGetTasksList(); }}>Reload</MyTag>
          {/*taskstype == 'test' &&
          (
          <MyTag color="cyan" onClick={(e) => { handleShow(); }}>Back to Detail</MyTag>
          )
          */}
          <Input.Search
            //style={{ marginLeft: 420 }}
            placeholder="keywords Search..."
            onSearch={handleSearch}
            allowClear
            enterButton
          />
        </Space>
      </Row>
      <Table
        columns={columns}
        rowKey="taskid"
        //dataSource={dataset}
        //dataSource={filterDataset}
        dataSource={filterDataset.filter(
          (item: any) =>
            (item.modelname || '').toLowerCase().includes(searchText.toLowerCase())
            || (item.datasetname || '').toLowerCase().includes(searchText.toLowerCase())
        )}
        pagination={{ pageSize: 10 }}
      //scroll={{ y: 600 }}
      />
      <ModalContainer isModalOpen={virtualModalVisible} isModalHide={isModalHide}>
        <span
          style={{
            fontSize: "1.5rem",
            position: "fixed",
            top: "-8px",
            right: "10px",
          }}
          onClick={handleVirtualOff}
        >
          <CloseCircleOutlined />
        </span>
        <ModalDisplayContainer>
          {datasetLogUrl ? (
            <TestResultVirtualDisplay
              imageCount={imageCount}
              setImageCount={setImageCount}
              datasetSrc={datasetLogUrl}
              taskId={currentDataset.taskId}
              datasetType={currentDataset.datasetType}
              handleGoLabel={handleGotoLabelFromVirtual}
              handlePreImageDom={handlePreImageDom}

            />
          ) : null}
        </ModalDisplayContainer>
      </ModalContainer>

      <ModalContainer isModalOpen={isModalVisible} isModalHide={isModalHide}>
        <span
          style={{
            fontSize: "1.5rem",
            position: "fixed",
            top: "-8px",
            right: "10px",
          }}
          onClick={handleModalCancel}
        >
          <CloseCircleOutlined />
        </span>
        <ModalDisplayContainer>
          <div>
            <IframeResizer
              log={false}
              inPageLinks
              forwardRef={iframeRef}
              onMessage={handleOnMessage}
              //onResized={onResized}        
              src={getResultUrl}
              width="100%"
              scrolling={true}
              height="800px"
              frameBorder="0"
            />
          </div>
        </ModalDisplayContainer>
      </ModalContainer>
      <ModalContainer isModalOpen={isImageModalVisible} isModalHide={isImageModalHide}>
        <span
          style={{
            fontSize: "1.5rem",
            position: "fixed",
            top: "-8px",
            right: "10px",
          }}
          onClick={handleImageModalCancel}
        >
          <CloseCircleOutlined />
        </span>
        <ModalDisplayImageContainer>
          <p>{imageCount && `${imageCount.index + 1}/${imageCount.total}`}</p>
          <TransformWrapper
            //centerOnInit
            initialScale={1}
            initialPositionX={0}
            initialPositionY={0}
            ref={refZoom}
          >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <React.Fragment>

                <div className="tools" style={{ width: 300, marginBottom: "10px", display: 'flex', justifyContent: 'space-around' }}>

                  <Button type="primary" shape="round" onClick={() => zoomIn()}>Zoom In</Button >
                  <Button type="primary" shape="round" onClick={() => zoomOut()}>Zoom Out</Button >
                  <Button type="primary" shape="round" onClick={() => resetTransform()}>Reset</Button >
                </div>
                <div style={{ position: "relative", display: "flex" }}>
                  <Button type="primary" icon={<LeftOutlined />} size={"large"} shape="circle"
                    onClick={() => handleChangePreImage("left")}
                    style={{
                      position: "absolute", top: 300 - 16, left: -550
                    }} />
                  <Button type="primary" icon={<RightOutlined />} size={"large"} shape="circle"
                    onClick={() => handleChangePreImage("right")}
                    style={{
                      position: "absolute", top: 300, left: 500
                    }} />

                </div>

                <TransformComponent>
                  {/* <img src={previewImage} alt='img' style={{ display: preImage.open ? "" : 'none' }} /> */}
                  {preImage.open && (
                    preImage.results ?
                      <VirtualImageComp
                        url={ preImage?.condition?.showHeat ? replaceLastSlashWithHeat(preImage.url) : preImage.url}
                        results={preImage.results}
                        condition={preImage?.condition}
                        width={780}
                      />
                      : <img src={preImage.url} alt='img' width={780} />
                  )
                  }
                </TransformComponent>
              </React.Fragment>
            )}
          </TransformWrapper>
        </ModalDisplayImageContainer>
      </ModalContainer>
      {/* <ModalContainer isModalOpen={isImageModalVisible}>

        <span
          style={{
            fontSize: "1.5rem",
            position: "fixed",
            top: "-8px",
            right: "10px",
          }}
          onClick={handleImageModalCancel}
        >
          <CloseCircleOutlined />
        </span>
        <ModalDisplayImageContainer>
          <img src={previewImage} width={'95%'}></img>
        </ModalDisplayImageContainer>
      </ModalContainer>  */}

    </>
  );
};

export default TasksValidTest;
