import { InputNumber, Slider } from 'antd';
import React, { ReactNode } from 'react';


const marks = {
  0: "0",
  10: "0.1",
  30: "0.3",
  50: "0.5",
  70: "0.7",
  80: "0.8",
  90: "0.9",
  100: "1.0",
};

interface SliderPropsType {
  range: [number, number];
  handleChangeRange: (newRange: [number, number]) => void;
  children?: ReactNode;
};


const fixedToNumber = (value: number) => {
  const n = 2;
  return Math.floor(value * Math.pow(10, n)) / (Math.pow(10, n));
};

const SliderContainter: React.FC<SliderPropsType> = ({
  range, handleChangeRange, children
}) => {


  const onChangeMin = (newValue: number | null) => {
    const [a, b] = range;
    handleChangeRange([(newValue ? newValue : 0) * 100, b]);
  };
  const onChangeMax = (newValue: number | null) => {
    const [a, b] = range;
    handleChangeRange([a, (newValue ? newValue : 0) * 100]);
  };

  return (

    <div>
      <Slider
        style={{ width: "80%" }}
        tipFormatter={(value) => (value ? fixedToNumber(value / 100) : 0)}
        range
        marks={marks}
        step={0.01}
        defaultValue={range}
        value={range}
        onChange={(value) => handleChangeRange([...value])}
      />
      <InputNumber
        min={0}
        max={1}
        step={0.01}
        defaultValue={0}
        value={fixedToNumber(range[0] / 100)}
        onChange={onChangeMin}
        disabled={false}
        style={{ marginLeft: 20, maxWidth: 70 }}
      />
      <InputNumber
        min={0}
        max={1}
        step={0.01}
        defaultValue={1}
        value={fixedToNumber(range[1] / 100)}
        onChange={onChangeMax}
        disabled={false}
        style={{ marginLeft: 20, maxWidth: 70 }}
      />
      {children}
    </div>

  );
};

export default SliderContainter