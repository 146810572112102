import { Button, Popover, Switch } from 'antd';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { Vector2d } from 'konva/lib/types';
import React, { useEffect, useMemo, useState } from 'react';
import { Image, Layer, Stage } from "react-konva";

import { MenuOutlined } from '@ant-design/icons';
import short from 'short-uuid';
import LabelSyntheticReCircle, { AnnotationType } from '../Label-Synthetic-ReCircle';
import LabelSyntheticRectangle from '../Label-Synthetic-Rectangle';


const ErrorType = {
    rectJustOne: 'One Synthetic just have one Cluster.',
    circleJustOne: 'More Clusters in the same Synthetic.',
    counts: 'Synthetic and Cluster counts do not match.',
    outRange: 'Cluster does not in Synthetic range.'
};

const initMenuPoint = { display: 'none', top: 0, left: 0, zIndex: 0, justifyContent: 'center', alignItems: 'center' }



const LabelSyntheticCanvas = ({
    canvasSource,
    annotations,
    updateAnnotations,
    onLabel,
    onCircle,
    onEdit,
    statusColor = '#F4D03F',
    height = 800,
    width,
    onStageClick,
    getCurrentRect,

}: {
    canvasSource: HTMLImageElement,
    annotations: AnnotationType[];
    updateAnnotations: (newAnnotations: AnnotationType[]) => void,
    onLabel: boolean,
    onCircle: boolean;
    onEdit: boolean,
    statusColor?: string;
    height?: number;
    width?: number;
    onStageClick?: () => void;
    getCurrentRect?: (selectId: string | null) => void,

}) => {
    const enableSelect = !onLabel && !onCircle && onEdit
    const [newAnnotation, setNewAnnotation] = useState<AnnotationType[]>([]);
    const [origin, setOrigin] = useState([0, 0]);

    const [selectedId, setSelectId] = React.useState<string | null>(null);
    const [menuPointer, setMenuPointer] = useState({ ...initMenuPoint, display: enableSelect ? 'block' : 'none' });
    const [rectCount, setRectCount] = useState(0);
    const [showLabel, setShowLabel] = useState(false);

    useEffect(() => {
        setRectCount(annotations.length);
    }, [annotations])
    /**
     * * 確認circle是否在rect內
     * @param ann 
     * @returns 
     */
    const checkQualified = (ann: AnnotationType) => {
        const type = ann.type;
        if (type === 'rect') {
            return { ...ann, qualified: true };
        } else if (type === 'circle') {
            const hasRect = ann.rectId;
            return { ...ann, qualified: Boolean(hasRect), error: !hasRect ? ErrorType.outRange : undefined };
        } else {
            return ann
        };
    };
    /**
     * * 計算劃出的圖形是否超出範圍，超出的邊數值改為範圍邊界數值
     * @param ann AnnotationType
     */
    const rangeLimit = (ann: AnnotationType) => {
        const limitW = canvasSource.width;
        const limitH = canvasSource.height;
        const { x, y, width, height, type } = ann;
        if (type === 'circle') {
            const r = ann.width;
            if ((x + r) > limitW || (y + r) > limitH) {

                ann.x = (x + r) > limitW ? (limitW - r) : x;
                ann.y = (y + r) > limitH ? (limitH - r) : y;
            }
            if ((x - r) < 0 || (y - r) < 0) {
                ann.x = (x - r) < 0 ? r : ann.x;
                ann.y = (y - r) < 0 ? r : ann.y
            }

        } else {
            if ((x + width) > limitW || (y + height) > limitH) {
                ann.x = (ann.x + ann.width) > limitW ? (limitW - width) : x;
                ann.y = (ann.y + ann.height) > limitH ? (limitH - height) : y;
            }
            if ((x < 0) || y < 0) {

                ann.x = ann.x < 0 ? 0 : ann.x;
                ann.y = ann.y < 0 ? 0 : ann.y;
            }
        }
        if (type === 'rect' && ((Math.abs(ann.x) + ann.width) > limitW || (Math.abs(ann.y) + ann.height) > limitH)) {
            ann.x = (Math.abs(ann.x) + ann.width) > limitW ? 1 : ann.x;
            ann.y = (Math.abs(ann.y) + ann.height) > limitH ? 1 : ann.y
            ann.width = (Math.abs(ann.x) + ann.width) > limitW ? limitW - Math.abs(ann.x) : ann.width;
            ann.height = (Math.abs(ann.y) + ann.height) > limitH ? limitH - Math.abs(ann.y) : ann.height;
        };
        if (type === 'circle' && ((ann.x - ann.width) < 0 || (ann.y - ann.height) < 0)) {
            const minSide = limitW < limitH ? limitW : limitH
            ann.x = minSide / 2;
            ann.y = minSide / 2;
            ann.width = minSide / 2;
            ann.height = minSide / 2
        };
        return ann
    };


    /**
     * * type為Circle的圖形去尋找它的中心座標是否落在rect的範圍內，取第一個被找到的rect id，無則回傳空字串
     * @param pos x,y
     * @returns 
     */
    const findRect = (pos: { x: number, y: number }, currentAnn: AnnotationType[]) => {
        const { x, y } = pos;
        const rects = currentAnn.filter(ann => ann.type === 'rect');
        const item = rects.find(rect => (x > rect.x && y > rect.y && x < (rect.x + rect.width) && y < (rect.y + rect.height)));
        return item ? item.id : ''
    };

    /**
     * * 檢查一個rect是否只配一個circle，假如有多個則判斷配的所有circle不合格
     * @param anns AnnotationType[ ]
     * @returns 
     */
    const checkRectMatchCircleCount = (anns: AnnotationType[]) => {
        const updatedAnnotations = [...anns];
        const beUpdated = new Array<AnnotationType>();
        const rects = updatedAnnotations.filter(ann => ann.type === 'rect');
        const circles = updatedAnnotations.filter(ann => ann.type === 'circle');
        rects.forEach(rect => {
            const rectHasCircle = circles.filter(circle => circle.rectId === rect.id);
            if (rectHasCircle.length > 1) {
                const unQualifiesRect = [rect, ...rectHasCircle].map(ann => {
                    return {
                        ...ann, qualified: false,
                        error: ann.type === 'circle' ? ErrorType.circleJustOne : ErrorType.rectJustOne
                    };
                });
                beUpdated.push(...unQualifiesRect);
            } else if (rectHasCircle.length <= 1) {

                beUpdated.push({ ...rect, qualified: true, error: undefined });
            };
        });
        const newMap = new Map(beUpdated.map(item => [item.id, item]));
        const merged = updatedAnnotations.map(item => newMap.has(item.id) ? newMap.get(item.id)! : item);
        return merged
    };

    /**
     * * 檢查要更新後的資料裡面的circle是否有配到rect
     * @param newAnn 
     * @returns 
     */
    const checkCircleInRect = (newAnn: AnnotationType[]) => {
        const updatedAnn = [...newAnn].map(rect => {
            if (rect.type === 'circle') {
                const inRectId = findRect({ x: rect.x, y: rect.y }, newAnn);
                const newRect = { ...rect, rectId: inRectId } as AnnotationType;
                return checkQualified(newRect)
            } else {
                return rect
            }
        });
        return updatedAnn;
    };

    /**
     * * 將資料經過檢查後做最後的更新
     * @param newAnn 
     * @returns 
     */
    const handleUpdateAnnotations = (newAnn: AnnotationType[]) => {
        const circleInRectsAnn = checkCircleInRect(newAnn);
        const checkBeforeUpdatedAnn = checkRectMatchCircleCount(circleInRectsAnn);
        return updateAnnotations(checkBeforeUpdatedAnn);
    };

    /**
     * * 當rect有變化時更新回去annotations
     * @param index annotations中的順序
     * @param ann AnnotationType
     * @returns 
     */
    const handleUpdatedByIndex = (index: number, ann: AnnotationType) => {
        const rects = [...annotations]
        const limitAnn = rangeLimit(ann);
        rects.splice(index, 1, limitAnn);
        return handleUpdateAnnotations([...rects]);
    };

    /**
     * * 選擇指定圖形id
     * @param id rect id
     * @returns 
     */
    const handleSelect = (id: string | null) => {
        if (!enableSelect) return;
        setSelectId(id)
        getCurrentRect && getCurrentRect(id)
        return;
    };

    /**
     * * 校正圖形座標 - 當畫的方向為右向左，座標會是負的，畫完後要校正為正 
     * @param ann AnnotationType
     * @returns 
     */
    const adjustAnnotation = (ann: AnnotationType) => {
        const { x, y, width, height } = ann;
        if (height < 0) {
            const newY = y + height
            ann.height = y - newY
            ann.y = newY
        };
        if (width < 0) {
            const newX = x + width;
            ann.width = x - newX;
            ann.x = newX
        }
        return ann;
    };


    const handleMouseDown = (event: Konva.KonvaEventObject<MouseEvent>) => {
        const mouseEvt = event.evt.button// left:0, right:2
        //console.log('evttt', event.target.getStage())
        if (newAnnotation.length === 0 && (onLabel || onCircle) && mouseEvt === 0) {
            const { x, y } = event.target.getStage()!.getPointerPosition() as Vector2d;
            const id = onLabel ? 'rect_' + short.uuid() : 'circle_' + short.uuid();
            const rectId = findRect({ x, y }, annotations);
            setOrigin([x, y]);
            const count = rectCount + 1;
            setNewAnnotation([{
                x, y, width: 0, height: 0, id: id,
                type: onLabel ? 'rect' : 'circle',
                rectId: onCircle ? rectId : id,
                qualified: Boolean(rectId),
                label: onLabel ? 'Synthetic-' + count : 'Cluster-' + count,
                error: !rectId ? ErrorType.outRange : undefined
            }]);
            setRectCount(count);
        }
        const clickedOnEmpty = event.target.getClassName() !== 'Rect';
        if (clickedOnEmpty) {
            handleSelect(null);
        };
        // next to mousemove
        return;
    };

    const handleMouseMove = (event: Konva.KonvaEventObject<MouseEvent>) => {
        if (newAnnotation.length === 1) {
            const target = newAnnotation[0]
            const sx = target.x;
            const sy = target.y;
            const { x, y } = event.target.getStage()!.getPointerPosition() as Vector2d;
            if (onLabel) {
                const ann = {
                    ...target,
                    x: sx,
                    y: sy,
                    width: x - sx,
                    height: y - sy,
                }
                setNewAnnotation([ann]);
            };
            if (onCircle) {
                const [ox, oy] = origin;
                const r = Math.abs(ox - x) > Math.abs(oy - y) ? Math.abs(ox - x) : Math.abs(oy - y)
                const ann = {
                    ...target,
                    width: r,
                    height: r
                };
                setNewAnnotation([ann]);
            }
        }
        // next to mouseup
    };

    const handleMouseUp = (event: Konva.KonvaEventObject<MouseEvent>) => {
        if (newAnnotation.length === 1 && (onLabel || onCircle)) {
            const updated = [...annotations];
            const target = newAnnotation[0];
            const adjust = adjustAnnotation(target)
            const qualified = checkQualified(adjust);
            const limit = rangeLimit(qualified);
            updated.push(limit);
            setNewAnnotation([]);
            handleUpdateAnnotations([...updated]);
        }
    };

    /**
     * * 按右鍵觸發menu context
     * @param e 
     */
    const handleContext = (e: KonvaEventObject<PointerEvent>) => {
        const point = e.target.getStage()!.getPointerPosition() as Vector2d;
        handleSelect(e.target.getAttrs().id);
        setMenuPointer({
            ...menuPointer,
            display: 'flex', top: point.y + 80, left: point.x + 40, zIndex: 999
        })
    };

    const handleDelete = () => {
        if (selectedId) {
            const updated = [...annotations].filter(ann => ann.id !== selectedId);
            setMenuPointer(initMenuPoint)
            console.log('5545444545', updated)
            return handleUpdateAnnotations(updated)
        }
    };

    /**
     * * stop stage event popup and 對stage按右鍵的額外操作，沒給void則不做任何事
     * @param e 
     * @returns 
     */
    const handleStageContext = (e: KonvaEventObject<PointerEvent>) => {
        e.evt.preventDefault();//stop stage event 
        return onStageClick && onStageClick()
    };

    const unQualifyAnnotations = useMemo(() => {
        return annotations.filter(ann => !ann.qualified)
    }, [annotations]);

    return (
        <React.Fragment>

            <Popover placement="bottomRight" title={'Information'} content={<div>
                <div>
                    <Switch size="small" checked={showLabel} onChange={(checked) => setShowLabel(checked)} /> Show Label
                </div>
                <div>
                    {unQualifyAnnotations.map(ann =>
                        <section key={ann.id} style={{ fontSize: 16 }}>{ann.label}: {ann.error}</section>
                    )}
                </div>

            </div>} trigger="click">
                <Button
                    size='large'
                    danger={unQualifyAnnotations.length > 0}
                    style={{ position: 'absolute', right: 440, zIndex: 999, background: 'white', top: 100 }}
                    icon={<MenuOutlined />}
                />
            </Popover>

            <div style={{ height: 50, width: 100, borderRadius: 5, background: 'white', position: 'absolute', padding: 10, ...menuPointer }}>
                <Button style={{ width: '100%' }} onClick={handleDelete}>Delete</Button>
            </div>
            <Stage
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
                width={canvasSource?.width}
                height={height}
                onContextMenu={handleStageContext}
                onClick={() => setMenuPointer(initMenuPoint)}
            >
                <Layer>
                    <Image image={canvasSource} />
                    {[...annotations, ...newAnnotation].map((ann, i) =>
                        ann.type === 'rect' ?
                            <LabelSyntheticRectangle
                                key={i}
                                shapeProps={ann}
                                isSelected={ann.id === selectedId}
                                statusColor={statusColor}
                                onLabel={onLabel}
                                dragEnable={enableSelect}
                                onSelect={() => handleSelect(ann.id)}
                                onChange={(newAttrs) => handleUpdatedByIndex(i, newAttrs)}
                                handleContext={handleContext}
                                showLabel={showLabel}
                            /> :
                            <LabelSyntheticReCircle
                                key={i}
                                shapeProps={ann}
                                isSelected={ann.id === selectedId}
                                statusColor={statusColor}
                                dragEnable={enableSelect}
                                onCircle={onCircle}
                                onSelect={() => handleSelect(ann.id)}
                                onChange={(newAttrs) => handleUpdatedByIndex(i, newAttrs)}
                                handleContext={handleContext}
                                showLabel={showLabel}
                            />
                    )}

                </Layer>
            </Stage>
        </React.Fragment>

    )
}



export default LabelSyntheticCanvas;