import {
  CheckOutlined,
  CheckSquareTwoTone,
  CloseOutlined,
  CloseSquareTwoTone,
  DownOutlined,
  LeftOutlined,
  RightOutlined,
  UpOutlined,
} from "@ant-design/icons";
import {
  Anchor,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Input,
  InputNumber,
  message,
  Modal,
  Popover,
  Radio,
  Slider,
  Switch,
  Image,
  Row,
  Col,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useVirtual } from "react-virtual";
import { offlineUrlExpress,angleFunction } from "../../urls/index";
import VirtualImageComp, { ResultType } from "../TestResult-Virtual-Image";
//import exportFromJSON from 'export-from-json';
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import ExportButton from "../TestResult-Export-Button";
import SliderContainter from "./Slider-Container";
//import JSZip from "jszip";

import { fullFunction } from "../../urls/index";

const { Search } = Input;
const { Panel } = Collapse;
const { Link } = Anchor;

export enum FilterByLabel {
  LABELED = "LABELED",
  NO_LABELED = "NO_LABELED",
  ALL = "ALL",
}

const setRowWidthAndHeight = (
  row_width: number,
  row_height: number,
  row_num: number,
  lists_length: number
) => {
  const num =
    lists_length % row_num === 0
      ? lists_length / row_num
      : lists_length / row_num + 1;
  return {
    width: row_width,
    height: row_height,
    rowSize: num,
    columnSize: row_num,
  };
};
export interface ConditionType {
  color: string;
  showBox: boolean;
  showClass: boolean;
  showHeat: boolean;
  classes: string;
  showScore: boolean;
  scoreRange: number[];
  distanceRange?:[number,number];
}
export interface DatasetVirtualDisplayProps {
  imageCount: { index: number; total: number } | null;
  setImageCount: React.Dispatch<
    React.SetStateAction<{ index: number; total: number } | null>
  >;
  datasetSrc: string;
  taskId: string;
  datasetType: string;
  handleGoLabel: any;
  handlePreImageDom: any;
}

export interface DatasetDetail {
  id: string | null;
  image_url: string;
  result: ResultType[] | null;
  image_name: string;
  predict: string | null;
  isLabel: boolean | null;
  labelindex: number | null;
}
export const initCondition: ConditionType = {
  color: "red",
  showBox: true,
  showClass: true,
  classes: "none",
  showScore: false,
  scoreRange: [0, 100],
  showHeat: false,
};
export const init_checked = {
  start: false,
  test: false,
  end: false,
};

const marks = {
  0: "0",
  10: "0.1",
  30: "0.3",
  50: "0.5",
  70: "0.7",
  80: "0.8",
  90: "0.9",
  100: "1.0",
};

const fixedToNumber = (value: number) => {
  const n = 2; 
  return Math.floor(value*Math.pow(10,n))/(Math.pow(10,n));
};

const DatasetVirtualDisplay: React.FC<DatasetVirtualDisplayProps> = ({
  imageCount,
  setImageCount,
  datasetSrc,
  taskId,
  datasetType,
  handleGoLabel,
  handlePreImageDom,
}) => {
  const colorChoice = ["red", "white"];
  const [imageResource, setImageResource] = useState<DatasetDetail[]>([]);
  const [imageElements, setImageElements] = useState<DatasetDetail[]>([]);
  const [imageFilterElements, setImageFilterElements] = useState<
    DatasetDetail[]
  >([]);
  const [classes, setClasses] = useState<string[]>([]);
  const [filterClass, setFilterClass] = useState<string | null>(null);
  const [searchKey, setSearchKey] = useState<string | undefined>(undefined);
  const [keyState, setKeyState] = useState<string | undefined>(undefined);
  const [textLog, setTextLog] = useState<string | undefined>(undefined);
  const [scoreArray, setScoreArray] = useState<[number, number]>([0, 100]); //顯示用
  const [scoreRange, setScoreRange] = useState<[number, number]>([0, 100]); //狀態用
  const [distanceRange,setDistanceRange]= useState<[number, number]>([0, 100]);
  const [percent, setPercent] = useState<number>(0);
  const [avgTime, setAvgTime] = useState<number>(0);
  const [condition, setCondition] = useState<ConditionType>(initCondition);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [showConfusionMatrixModal, setShowConfusionMatrixModal] = useState<boolean>(false);
  const [foundConfusionMatrix, setFoundConfusionMatrix] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [dataIndex, setDataIndex] = useState<number>(0);
  const [labelType, setLabelType] = useState<FilterByLabel>(FilterByLabel.ALL);
  const [detail, setDetail] = useState();
  const [showExport, setShowExport] = useState<boolean>(false);
  const [showBottomIcon, setShowBottomIcon] = useState<boolean>(true);
  const [showTopIcon, setShowTopIcon] = useState<boolean>(false);
  const [startCheck, setStartCheck] = useState<boolean>(false);
  const [testCheck, setTestCheck] = useState<boolean>(false);
  const [endCheck, setEndCheck] = useState<boolean>(false);
  const [mainTitle, setMainTitle] = useState<any>(null);
  const [finish, setFinish] = useState<boolean>(false);
  const [isAngle,setIsAngle] = useState<boolean>(false);
  const [isHeatImage,setIsHeatImage] = useState<boolean>(false);

  const [showScoreImage, setShowScoreImage] = useState<boolean>(false);

  const [cfmValue, setcfmValue] = useState<number>(0.5);

  const parentRef = React.useRef<HTMLDivElement | null>(null);

  const callback = (key: string | string[]) => {
    // setPanelKey(key);
  };
  const { width, height, rowSize, columnSize } = setRowWidthAndHeight(
    600,
    550,
    3,
    imageElements.length
  );

  const rowVirtualizer = useVirtual({
    size: rowSize,
    parentRef,
    estimateSize: React.useCallback(() => height, []), //height
    overscan: 50,
  });

  const columnVirtualizer = useVirtual({
    horizontal: true,
    size: columnSize,
    parentRef,
    estimateSize: React.useCallback(() => width, []), //width
    overscan: 50,
  });

  const getIndex = (x: number, y: number) => x + columnSize * y;

  const TextForJson = (text: string) => {
    try {
      let new_text = JSON.parse(text);
      return new_text;
    } catch (err: any) {
      let text_array = text.split(",");
      return JSON.parse(text_array[0] + "}");
    }
  };

  const handleReset = () => {
    setSearchKey(undefined);
    setFilterClass(null);
    setImageFilterElements([...imageResource]);
    setImageElements([...imageResource]);
    setScoreArray([0, 100]);
  };

  const loadingLog = (text: string) => {
    let finishText = text
      .match(/(testing_epoch finish: 0.[0-9]*)/gm)
      ?.slice(-1)[0]
      .split(":")[1];
    if (text.match(/[test start]/)) {
      setStartCheck(true);
    }
    if (finishText) {
      let per = parseFloat(finishText);
      setPercent(per);
      setTestCheck(true);
    }
    if (text.match(/(test finish)/)) {
      setPercent(1);
      setEndCheck(true);
    }
    let avgTimeText = text.match(/(average time\(s\):\d+.\d+)/gm)
    ?.slice(-1)[0]
    .split(":")[1];
    if (avgTimeText) {
      let per =  (Math.round(parseFloat(avgTimeText) * 100) / 100);
      setAvgTime(per);
    } else {
      setAvgTime(0);
    }    

  };
  /**
   * * 計算指定的class的總數量
   */
  const collection = (source: DatasetDetail[], cls: string) => {
    if (cls === "none") {
      const list = source.filter((ele) => ele.result?.length === 0);
      return [0, list.length];
    }
    const list = source.filter((ele) => ele.result && ele.result.length > 0);
    let clsElement = list.map((ele) => {
      const classes = ele.result!.filter((x) => x.class === cls);
      return {
        ...ele,
        result: classes,
      };
    });
    const clsElementFilterResult = clsElement.filter(
      (ele) => ele.result.length > 0
    );
    const clsCount = clsElementFilterResult.reduce(
      (pre, cur) => pre + cur.result.length,
      0
    );
    return [clsCount, clsElementFilterResult.length];
  };

  const setElements = (data: DatasetDetail[]) => {
    setImageFilterElements([...data]);
    setImageResource([...data]);
    setImageElements([...data]);
  };

  const getDetail = (text: string) => {
    const rex = /^{\"modelname\":.*}$/gm;
    const textArray = text.match(rex);
    if (textArray) {
      const detail = JSON.parse(textArray![0]);
      setDetail(detail);
    }
  };

  const checkIsAngle=(params:any)=>{
   
    if(params['angle']==='true') setIsAngle(true);
    else setIsAngle(false);
    return;
  };

  const filterConfusionMatrixData = (text: string) => {
    const re = /confusionchart.*.png/gm;
    const text_array = text.match(re);
    console.log("text_array-->",text_array);
    if (text_array !== null && text_array.length > 0) {
      setFoundConfusionMatrix(true);
    } else {
      setFoundConfusionMatrix(false);
    }
  }

  const filterDataByObject = (text: string) => {
    // const title = JSON.parse(text.match(/.*/)![0])
    const title = JSON.parse(text.match(/{\"modelname\":.*}/)![0]);
    checkIsAngle(title.params);
    setMainTitle(title);
    //const re1 = /^{\"image_url\":.*}$/gm;
    let re1 = /^{\"id\":.*}$/gm;
    let text_array = text.match(re1);
    if (text_array == null) {
      re1 = /^{\"image_url\":.*}$/gm;
      text_array = text.match(re1);
    }

    //console.log("text_array-->",text_array);
    getDetail(text);
    // text_array?.pop();//< 拿掉最後一個
    if (text_array && text_array.length > 2) {
      try {
        const resultDemo = text_array.map((item: string) => TextForJson(item));
        const SetOfClass = new Set<string>();
        const datasetResult = new Array<any>();

        const result_format = resultDemo.map((item: DatasetDetail) => {
          const image_name = item.image_url.split("/").slice(-1)[0];
          const image_url = `${offlineUrlExpress}/logs/${taskId}/${image_name}`;

          if (item.result && item.result?.length > 0) {
            datasetResult.push(item.result);
            item.result.map((item: any) => {
              SetOfClass.add(item.class);
            });
          }
          return {
            ...item,
            image_url: decodeURI(image_url),
            image_name: decodeURI(image_name),
          };
        });
        SetOfClass.add("none");
        let array = Array.from(SetOfClass);
        setClasses([...array]);

        fetchLabel(taskId, result_format)
          .then((result) => {
            //console.log(result)
            if (result) {
              setElements(result);
            }
          })
          .catch((error) => {
            console.log(error);
            setElements(result_format);
          });
        return true;
      } catch (err) {
        return null;
      }
    } else {
      return null;
    }
  };

  const filterDataByHttp = (text: string) => {
    const re2 = /http+.*g/gm;
    const text_array = text.match(re2);
    getDetail(text);
    // text_array?.pop();//< 拿掉最後一個
    if (text_array) {
      const filterText = text_array.filter((t) => t.indexOf("jpg") > -1);
      const result_format = filterText.map((item: string) => {
        const image_name = item.split("/").slice(-1)[0];
        return {
          id: null,
          image_url: decodeURI(item),
          result: [],
          image_name: decodeURI(image_name),
          predict: null,
          isLabel: null,
          labelindex: null,
        };
      });
      fetchLabel(taskId, result_format)
        .then((result) => {
          if (result) {
            setElements(result);
          }
        })
        .catch((error) => {
          console.log(error);
          setElements(result_format);
        });
    }
  };

  const fetch = async (url: string) => {
    try {
      // const res = await axios.get(`${offlineUrlExpress}/logs/aa9f8805-7259-405a-9fb9-1bbf5fb50620/log.txt`);
      const res = await axios.get(url);
      loadingLog(res.data);
      const text = res.data.replaceAll("'", '"');
      filterConfusionMatrixData(text);
      const jsonType = filterDataByObject(text);
      setTextLog(res.data);
      if (!jsonType) {
        filterDataByHttp(res.data);
      }
    } catch (err: any) {
      setImageElements([]);
    }
  };

  const fetchLabel = async (taskId: string, source: DatasetDetail[]) => {
    if (taskId.length > 0) {
      try {
        const res = await axios.post(
          `${offlineUrlExpress}/api/check_testresult_image_islabel/${taskId}`
        );
        const labels = res.data; //{filename:string,labeled:boolean}
        if (labels.length > 0) {
          return source.map((item: DatasetDetail) => {
            let data = labels.filter(
              //(labelItem: any) => labelItem.filename === item.image_name
              (labelItem: any) => labelItem.id === item.id
            )[0];
            if (data === undefined ) {
              data = labels.filter(
                //(labelItem: any) => labelItem.filename === item.image_name
                (labelItem: any) => labelItem.filename === item.image_name
              )[0];
            }


            if (data === undefined) {
              data = {};
              data.labeled = false;
              data.labelindex = -1;
            }
            // console.log(data);
            return {
              ...item,
              isLabel: data.labeled,
              labelindex: data.labelindex
            };
          });
        } else {
          return source;
        }
      } catch (err: any) {
        console.log(err);
      }
    }
  };


  const fetchLabelIndex = async (datasetid: string, source: DatasetDetail[]) => {
    if (datasetid.length > 0) {
      try {
        const res = await axios.post(
          `${offlineUrlExpress}/api/datasetimagesindex/${datasetid}`
        );
        const labels = res.data; //{filename:string,labelindex:int}
        if (labels.length > 0) {
          return source.map((item: DatasetDetail) => {
            let data = labels.filter(
              (labelItem: any) => labelItem.filename === item.image_name
            )[0];
            if (data === undefined) {
              data = {};
              data.labelindex = -1;
            }
            // console.log(data);
            return {
              ...item,
              isLabel: data.labelindex,
            };
          });
        } else {
          return source;
        }
      } catch (err: any) {
        console.log(err);
      }
    }
  };

  const filterByClass = (source: DatasetDetail[], target: string | null) => {
    const result = source;
    if (!target) {
      return result;
    }
    if (target === "none") {
      return source.filter((ele) => ele.result?.length === 0);
    }
    if (target !== "none") {
      let a = result.filter((ele) => ele.result && ele.result.length > 0);

      let b = a.map((ele) => {
        const results = ele.result!.filter((x) => x.class === target);
        return {
          ...ele,
          result: results,
        };
      });
      return b.filter((ele) => ele.result.length > 0);
    }
    return result;
  };

  const filterByScore = (source: DatasetDetail[]) => {
    const [minnum, maxnum] = scoreRange;
    if (minnum === 0 && maxnum === 100) {
      return source;
    }
    //過濾result長度 > 0

    const filters = source.map((ele) => {
      console.log(ele);
      //console.log("fixed,min,max-->",fixedToNumber( ele.result ? ele.result.score : 0), minnum / 100 , maxnum / 100);

      const results =
        ele.result &&
        ele.result.filter(
          (cls) =>
          cls.score >= fixedToNumber(minnum / 100) &&
          cls.score <= fixedToNumber(maxnum / 100)
          //fixedToNumber(cls.score) >= minnum / 100 &&
          //fixedToNumber(cls.score) <= maxnum / 100
        );
      return {
        ...ele,
        result: results,
      };
    });
    const elements = filters.filter(
      (item) => item.result && item.result?.length > 0
    );
    return elements;
  };

  const filterByKey = (source: DatasetDetail[], key: string | undefined) => {
    if (!key) return source;
    return source.filter((item: DatasetDetail) =>
      item.image_name.includes(key)
    );
  };

  const handleSearch = (value: string) => {
    setKeyState(value);
  };

  const  handleChangeScore=(newScore:[number,number])=>setScoreArray(newScore);
  /**
   * * 過濾分數不符合的圖片
   */
  const handleScoringImage = () => {
    setShowScoreImage(false);
    return handleFilterByScore();
  };
  /**
   * *只過濾分數不過濾圖片的圖片
   */
  const handleScoringAll = () => {
    setShowScoreImage(true);
    return handleFilterByScore();
  };
  const handleFilterByScore = () => {
    const [min, max] = scoreArray;
    setScoreRange(scoreArray);
    console.log("scoreRange -->" ,fixedToNumber(min / 100), fixedToNumber(max / 100));
    setCondition({ ...condition, scoreRange: [fixedToNumber(min / 100), fixedToNumber(max / 100)] });
  };

  //distance range setting function area
  const handleChangeDistance=(newRange:[number,number])=>setDistanceRange(newRange);

  const filterByDistance=()=>{
    const [min, max] = distanceRange;
    return setCondition({...condition,distanceRange:[min / 100, max / 100]});
    
  }


  const handleFilterClass = (cls: string | null) => {
    setFilterClass(cls);
  };

  const handleScrollButton = () => {
    if (parentRef.current) {
      let n = Math.ceil(imageElements.length / 156);
      const p = parentRef.current;
      const winheight = p.scrollHeight * n - 800;
      if (p.scrollTop > winheight) {
        setShowBottomIcon(false);
        setShowTopIcon(true);
      } else if (p.scrollTop === 0) {
        setShowTopIcon(false);
        setShowBottomIcon(true);
      } else {
        setShowBottomIcon(true);
        setShowTopIcon(true);
      }
    }
  };

  const handleExport = () => {
    //過濾條件
    setShowExport(true);
  };

  const initState = () => {
    setDataIndex(0);
    setStartCheck(false);
    setTestCheck(false);
    setEndCheck(false);
    setTextLog(undefined);
    setClasses([]);
    setFilterClass(null);
    setImageResource([]);
    setImageElements([]);
    setImageFilterElements([]);
    setIsAngle(false)
  };

  useEffect(() => {
    if (endCheck) {
      return;
    }
    const loopFetch = setInterval(() => {
      fetch(datasetSrc.toString());
    }, 3000);
    return () => clearInterval(loopFetch);
  }, [endCheck]);

  useLayoutEffect(() => {
    handleReset();
    setImageCount(null);
    setShowExport(false);
    initState();
    setLabelType(FilterByLabel.ALL);
    setCondition(initCondition);
    setScoreArray([0, 100]);
    setShowBottomIcon(true);
    setShowTopIcon(false);
    fetch(datasetSrc.toString());
    if (endCheck) {
      setFinish(true);
    }
  }, [datasetSrc, taskId]);

  useEffect(() => {
    if (imageElements.length > 0) {
      handleScrollButton();
    }
  }, [parentRef?.current?.scrollTop]);

  useEffect(() => {
    let updatedData = imageResource;
    updatedData = filterByKey(updatedData, keyState);
    updatedData = showScoreImage ? updatedData : filterByScore(updatedData);
    switch (labelType) {
      case FilterByLabel.LABELED:
        updatedData = updatedData.filter((item: DatasetDetail) => item.isLabel);
        break;
      case FilterByLabel.NO_LABELED:
        updatedData = updatedData.filter(
          (item: DatasetDetail) => !item.isLabel
        );
        break;
      case FilterByLabel.ALL:
      default:
        break;
    }

    setImageFilterElements([...updatedData]);
    // setImageElements([...updatedData]);
  }, [scoreRange, keyState, imageResource, labelType, showScoreImage]);

  useEffect(() => {
    let updatedData = imageFilterElements;
    updatedData = filterByClass(updatedData, filterClass);
    // setImageFilterElements([...updatedData]);

    setImageElements([...updatedData]);
  }, [filterClass, imageFilterElements]);

  const scrollToTop = () => {
    if (parentRef && parentRef.current) {
      parentRef.current.scrollTo({
        top: 0,
      });
      handleScrollButton();
    }
  };

  const scrollToBottom = () => {
    let n = Math.ceil(imageElements.length / 156);
    if (parentRef && parentRef.current) {
      let h = parentRef.current.scrollHeight * n;
      parentRef.current.scrollTo({
        top: h,
      });
      handleScrollButton();
    }
  };

  const scoreContent = (scores: any) => {
    const [min, max] = scoreRange;
    return (
      <div style={{ maxHeight: 500, overflow: "auto" }}>
        {scores
          .filter(
            (item: ResultType) =>
            item.score >= min / 100 &&
            item.score <= max / 100            
            //fixedToNumber(item.score) >= min / 100 &&
            //fixedToNumber(item.score) <= max / 100
          )
          .map((item: ResultType, key: string) => (
            <p key={key}>{`${key}. ${item.class}: [${item.score}]`}</p>
          ))}
      </div>
    );
  };

  const replaceLastSlashWithHeat = (url:any) => {
    return url.replace(/\/([^/]*)$/, "/heat/$1");
  };

  const handleImageDom = (
    url: string,
    result: ResultType[] | null,
    condition: ConditionType
  ): JSX.Element => (
    <VirtualImageComp
      url={condition.showHeat ? replaceLastSlashWithHeat(url): url}
      results={result}
      condition={condition}
      width={480}
    />
  );


  
  const onColorChange = (e: CheckboxChangeEvent) => {
    setCondition({ ...condition, color: e.target.value });
  };

  const EditContent = (
    <div>
      <p>
        show box
        <Switch
          checked={condition.showBox}
          size="small"
          defaultChecked
          style={{ float: "right" }}
          onChange={(checked: boolean) =>
            setCondition({
              ...condition,
              showBox: checked,
            })
          }
        />
      </p>
      <p>
        show class
        <Switch
          disabled={!condition.showBox}
          checked={condition.showClass}
          size="small"
          defaultChecked
          style={{ float: "right" }}
          onChange={(checked: boolean) =>
            setCondition({
              ...condition,
              showClass: checked,
            })
          }
        />
      </p>
      <p>
        show scores
        <Switch
          disabled={!condition.showBox}
          checked={condition.showScore}        
          size="small"
          defaultChecked
          style={{ float: "right" }}
          onChange={(checked: boolean) =>
            setCondition({
              ...condition,
              showScore: checked,
            })
          }
        />
      </p>
      {datasetType === "ABNORMAL" &&
      (<p>
        show heat
        <Switch
          disabled={datasetType !== "ABNORMAL"}
          checked={condition.showHeat}
          size="small"
          defaultChecked
          style={{ float: "right" }}
          onChange={(checked: boolean) =>
            setCondition({
              ...condition,
              showHeat: checked,
            })
          }
        />
      </p>)
      }    
    </div>
  );
  const SubmitDownload = async (results: any[]) => {
    try {
      setConfirmLoading(true);
      const res = await axios.post(
        `${offlineUrlExpress}/api/testresult/download`,
        { ...mainTitle, results },
        { responseType: "blob" }
      );

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "TestResult_" + mainTitle["datasetname"] + ".zip"
      );
      document.body.appendChild(link);
      link.click();
      setConfirmLoading(false);
      message.success(`${mainTitle["datasetname"]} download successfully`);
    } catch (err: any) {
      console.log(err.message);
      setConfirmLoading(false);
    }
  };
  const handleDownload = async (condition: ConditionType) => {
    const { showBox, showClass, showScore, showHeat } = condition;
    let results: any[] = imageFilterElements;
    if (!showBox) {
      results = results.map((data: DatasetDetail) => {
        const { image_name, image_url } = data;
        return {
          image_name,
          image_url,
          result: [],
        };
      });
      SubmitDownload(results);
      return;
    } else {
      results = results.map((data: DatasetDetail) => {
        const { image_name, image_url, result } = data;
        const result1 = result?.map((ele) => {
          return {
            bbox: ele.bbox,
            class: showClass ? ele.class : null,
            score: showScore ? ele.score : null,
          };
        });
        return {
          image_name,
          image_url,
          result: result1,
        };
      });
      SubmitDownload(results);
      return;
    }
  };

  const downloadModal = (
    imageData: DatasetDetail | null,
    show: boolean,
    condition: ConditionType
  ) => {
    return (
      <Modal
        title="Download Result Preview"
        visible={show}
        onOk={() => handleDownload(condition)}
        okText={"Download"}
        onCancel={() => setShowDownloadModal(false)}
        confirmLoading={confirmLoading}
        width={700}
      >
        <div style={{ position: "relative" }}>
          <p style={{ margin: "auto", width: 500 }}>
            Current Image Setting
            <Popover
              placement="leftTop"
              content={EditContent}
              title="Edit Image Performance"
              trigger="click"
              visible={showEdit}
              onVisibleChange={() => setShowEdit((prev) => !prev)}
            >
              {/*<Button style={{float:'right'}}>Display</Button>*/}
            </Popover>
          </p>
          <p style={{ margin: "auto", width: 500 }}>
            Total: {imageElements.length}
          </p>
          <p style={{ margin: "auto", width: 500 }}>Index: {dataIndex + 1}</p>

          {imageData ? (
            <div style={{ margin: "auto", width: 500 }}>
              <TransformWrapper
                //centerOnInit
                initialScale={1}
                initialPositionX={0}
                initialPositionY={0}
              >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                  <React.Fragment>
                    <div
                      className="tools"
                      style={{
                        margin: "auto",
                        width: 300,
                        marginBottom: "10px",
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <Button
                        type="primary"
                        shape="round"
                        onClick={() => zoomIn()}
                      >
                        Zoom In
                      </Button>
                      <Button
                        type="primary"
                        shape="round"
                        onClick={() => zoomOut()}
                      >
                        Zoom Out
                      </Button>
                      <Button
                        type="primary"
                        shape="round"
                        onClick={() => resetTransform()}
                      >
                        Reset
                      </Button>
                    </div>
                    <TransformComponent>
                      <VirtualImageComp
                        url={`${offlineUrlExpress}/logs/${taskId}/${imageData.image_name}`}
                        results={imageData.result}
                        condition={condition}
                        width={500}
                      />
                    </TransformComponent>
                  </React.Fragment>
                )}
              </TransformWrapper>
            </div>
          ) : null}

          <Button
            style={{ position: "absolute", top: 250 }}
            type="text"
            icon={<LeftOutlined style={{ fontSize: 40 }} />}
            disabled={dataIndex === 0}
            onClick={() => setDataIndex((pre) => pre - 1)}
          />
          <Button
            style={{ position: "absolute", top: 250, left: 610 }}
            type="text"
            icon={<RightOutlined style={{ fontSize: 40 }} />}
            disabled={dataIndex === imageElements.length - 1}
            onClick={() => setDataIndex((pre) => pre + 1)}
          />
        </div>
      </Modal>
    );
  };
  

  const confusionMatrixModal = (
    show: boolean,
  ) => {
    return (
      <Modal
        title="Confusion Matrix Chart"
        visible={show}
        onOk={() => setShowConfusionMatrixModal(false)}
        okText={"Close"}
        onCancel={() => setShowConfusionMatrixModal(false)}
        confirmLoading={confirmLoading}
        width={1200}
      >
        <div style={{ position: "relative" }}>
          <p style={{ margin: "auto", width: 1000 }}>
          <Row>
          <Col span={3}>
          Confidence : 
          </Col>
          <Col span={14}>
            <Slider value={cfmValue} min={0.0} step={0.1} max={0.9} onChange={setcfmValue}  />
          </Col>
          <Col span={3}>
            <InputNumber
              value={cfmValue}
              disabled
            />
          </Col>
        </Row>
        <br/>
          <Image src={`${offlineUrlExpress}/logs/${taskId}/confusionchart${(cfmValue * 100).toString().padStart(2, '0')}.png`}/>
          </p>
        </div>
      </Modal>
    );
  };

  const handleClickImage = (index: number) => {
    if (imageElements.length > 0 && index >= 0) {
      const { image_name, result } = imageElements[index];
      const url = `${offlineUrlExpress}/logs/${taskId}/${image_name}`;
      return handlePreImageDom(url, result, condition, index);
    }
  };

  const handleModalClose = () => setShowExport(false);

  const onChangeMin = (newValue: number) => {
    const [a, b] = scoreArray;
    setScoreArray([newValue * 100, b]);
  };
  const onChangeMax = (newValue: number) => {
    const [a, b] = scoreArray;
    setScoreArray([a, newValue * 100]);
  };
  useEffect(() => {
    if (imageCount && imageCount.index >= 0) {
      const imageIndex = imageCount.index;
      handleClickImage(imageIndex);
    }
  }, [imageCount]);

  useEffect(() => {
    //index = -1 #防止一更新資料長度頁面就觸發handleClickImage
    setImageCount({ index: -1, total: imageElements.length });
    setcfmValue(0.5);
  }, [imageElements]);

  return (
    <Collapse
      defaultActiveKey={["1"]}
      onChange={(key) => callback(key)}
      style={{ marginLeft: 10 }}
    >
      <Panel
        header="Model Testing Status"
        key="1"
        extra={<Button onClick={() => handleReset()}>Reset</Button>}
      >
        <div
          style={{
            margin: "auto",
            width: "100%",
          }}
        >
          {downloadModal(
            imageElements[dataIndex],
            showDownloadModal,
            condition
          )}

          {confusionMatrixModal(
            showConfusionMatrixModal
          )}



          <Button
            type="primary"
            shape="circle"
            size="large"
            icon={<DownOutlined />}
            onClick={scrollToBottom}
            style={{
              display: showBottomIcon ? "" : "none",
              position: "absolute",
              top: "90%",
              left: "93%",
              zIndex: 999,
            }}
          />
          <Button
            type="primary"
            shape="circle"
            size="large"
            icon={<UpOutlined />}
            onClick={scrollToTop}
            style={{
              display: showTopIcon ? "" : "none",
              position: "absolute",
              top: "90%",
              left: "96%",
              zIndex: 999,
            }}
          />
          <div id="header-container" style={{ height: "150px", display: "flex", marginLeft: 20 }}>
            <div
              id="load-progress-container" //解析進度
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent:"center",
                width: "10%",
              }}
            >
              <Checkbox style={{ display: "none" }}></Checkbox>
              <Checkbox checked={startCheck}>Test Start</Checkbox>
              <Checkbox checked={testCheck}>
                Testing ({percent * 100}%)
              </Checkbox>
              <Checkbox checked={endCheck}>Test End</Checkbox>
            {/* Function Remark */}
            {fullFunction &&
              <Checkbox checked={isAngle} style={{ display: angleFunction ? "" : "none" }}>Is Angle</Checkbox>
            }
            </div>
            <div 
              id="classes-filter-container" //類別
              style={{  width: "30%" }}>
              {classes.length > 0 ? (
                <>
                  <h2>filter class (classes/images)</h2>
                  {classes.map((cls: string, key: number) => {
                    const source = imageFilterElements;
                    let [clsCount, pageCount] = collection(source, cls);
                    let str = `${cls} (${clsCount}/${pageCount})`;
                    return (
                      <Button
                        key={key}
                        onClick={() => handleFilterClass(cls)}
                        style={{ margin: 5 }}
                        danger={filterClass === cls}
                      >
                        {str}
                      </Button>
                    );
                  })}
                  <Button
                    onClick={() => handleFilterClass(null)}
                    style={{ margin: 5 }}
                    danger={!filterClass}
                  >
                    {`all(${imageFilterElements.length})`}
                  </Button>
                </>
              ) : null}
              <br/><br/>
            {avgTime > 0 &&
              <b>AVG Predict Time : {avgTime} second</b>
            }
            </div>
           
            <div id="label-condition-container" //label
            style={{ margin:'auto', width: "8%" }}>
              <h2>check Labeled</h2>
              {/* <Button onClick={()=>setLabelType(FilterByLabel.LABELED)} style={{margin:5}} danger={labelType==="LABELED"} icon={<CheckOutlined />} />
                    <Button onClick={()=>setLabelType(FilterByLabel.NO_LABELED)} style={{margin:5}} danger={labelType==="NO_LABELED"} icon={<CloseOutlined />} />
                    <Button onClick={()=>setLabelType(FilterByLabel.ALL)} style={{margin:5}} danger={labelType==="ALL"}>
                        all
                            </Button>  */}
              <Radio.Group
                value={labelType}
                onChange={(e) => setLabelType(e.target.value)}
                optionType="button"
              >
                <Radio.Button value={FilterByLabel.LABELED}>
                  <CheckOutlined style={{ fontSize: 12 }} />
                </Radio.Button>
                <Radio.Button value={FilterByLabel.NO_LABELED}>
                  <CloseOutlined />
                </Radio.Button>
                <Radio.Button value={FilterByLabel.ALL}>All</Radio.Button>
              </Radio.Group>
            </div>
            <div id="search-container" style={{ margin:'auto', display: "flex", width: "10%" }}>
              <Search
                placeholder="input search keys"
                onSearch={(value) => handleSearch(value)}
                onChange={(e) => setSearchKey(e.target.value)}
                value={searchKey}
                enterButton
              />
            </div>
            <div id="range-slider-container">
               <div style={{position:'relative', marginLeft: 50, marginTop: 0, width: "26em" }}>
                <p style={{position:'absolute',left:-70}}>Score</p>
                <SliderContainter range={scoreArray} handleChangeRange={handleChangeScore}>
                  &nbsp;Filter:
                  <Button
                        type="primary"
                        onClick={() => handleScoringImage()}
                        style={{ marginLeft: 10 }}
                        size="small"
                    >
                      Image
                  </Button>
                  <Button
                      type="primary"
                      onClick={() => handleScoringAll()}
                      style={{ marginLeft: 10 }}
                      size="small"
                  >
                      Score
                  </Button>
                </SliderContainter>
                
                </div>
               {isAngle?
                 <div style={{position:'relative', marginLeft: 50, marginTop: 0, width: "26em" }}>
                  <p style={{position:'absolute',left:-70}}>Distance</p>
                   <SliderContainter range={distanceRange} handleChangeRange={handleChangeDistance}>
                    &nbsp;Filter:
                    <Button
                        type="primary"
                        onClick={() => filterByDistance()}
                        style={{ marginLeft: 10 }}
                        size="small"
                      >
                        distance
                    </Button>
                   </SliderContainter>
                 </div>:<div></div>}
             
            </div>

            <div
              id="export-download-bar-settings-container"
              style={{
                marginLeft: 40,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Popover
                placement="rightTop"
                content={EditContent}
                title="Edit Image Performance"
                trigger="click"
                visible={showEdit}
                onVisibleChange={() => setShowEdit((prev) => !prev)}
              >
                <Button>Display</Button>
              </Popover>

              <Button
                style={{ marginTop: 2 }}
                onClick={() => setShowDownloadModal(true)}
              >
                Download Result
              </Button>

              {foundConfusionMatrix && 
                <Button
                  style={{ marginTop: 2 }}
                  onClick={() => setShowConfusionMatrixModal(true)}
                >
                  Confusion Matrix
                </Button>
              } 

              {/* <Button1 onClick={() => handleExport()} style={{ marginTop: 2 }}>
                Export to Dataset
                          </Button> */}
              <ExportButton
                imageElements={imageElements}
                taskId={taskId}
                condition={condition}
                detail={detail}
              />
            </div>
          </div>
          <Divider /> 
          <div
            ref={parentRef}
            className="List"
            style={{
              margin: "auto",
              height: `740px`,
              width: `100%`,
              overflow: "auto",
            }}
          >
            {imageElements.length !== 0 && taskId ? (
              <div
                style={{
                  height: `${rowVirtualizer.totalSize}px`,
                  width: `${columnVirtualizer.totalSize}px`,
                  position: "relative",
                }}
              >
                {rowVirtualizer.virtualItems.map((virtualRow) => (
                  <React.Fragment key={virtualRow.index}>
                    {columnVirtualizer.virtualItems.map((virtualColumn) => {
                      const index = getIndex(
                        virtualColumn.index,
                        virtualRow.index
                      );

                      if (imageElements[index]) {
                        const { image_name, result, isLabel, labelindex } =
                          imageElements[index];

                        const scores = result?.filter((item) => item.score);
                        const url = `${offlineUrlExpress}/logs/${taskId}/${image_name}`;
                        const imageDom = handleImageDom(url, result, condition);
                        return (
                          <div
                            key={virtualColumn.index}
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 50,
                              padding: "10px",
                              width: `${virtualColumn.size}px`,
                              height: `${virtualRow.size}px`,
                              transform: `translateX(${virtualColumn.start}px) translateY(${virtualRow.start}px)`,
                            }}
                          >
                            <pre style={{ whiteSpace: "pre-wrap" }}>
                              {`(${index + 1}/${imageElements.length})[${labelindex}]`}{" "}
                              {image_name}
                            </pre>
                            <div style={{ display: "flex" }}>
                              <div style={{ paddingRight: "10px" }}>
                                {isLabel ? (
                                  <CheckSquareTwoTone
                                    style={{ fontSize: "22px" }}
                                  />
                                ) : (
                                  <CloseSquareTwoTone
                                    style={{ fontSize: "22px" }}
                                  />
                                )}
                              </div>
                              <Button
                                onClick={() => handleGoLabel(url)}
                                size="small"
                              >
                                [ Go to Label ]
                              </Button>
                              {scores && scores.length > 0 ? (
                                <Popover
                                  content={scoreContent(scores)}
                                  title="Score"
                                  trigger="click"
                                >
                                  <Button
                                    size="small"
                                    style={{ marginLeft: 10 }}
                                  >
                                    Score
                                  </Button>
                                </Popover>
                              ) : null}
                            </div>
                            <div
                              onClick={() =>
                                imageCount &&
                                setImageCount({ ...imageCount, index: index })
                              }
                            >
                              {imageDom}
                            </div>
                          </div>
                        );
                      }
                    })}
                  </React.Fragment>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </Panel>
      <Panel header="Log" key="2">
        <TextArea
          value={textLog}
          style={{
            fontSize: "14px",
            color: "white",
            backgroundColor: "black",
            height: "600px",
          }}
          disabled
        />
      </Panel>
    </Collapse>
  );
};

export default React.memo(DatasetVirtualDisplay);
