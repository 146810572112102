
import { KonvaEventObject } from 'konva/lib/Node';
import React from 'react';
import { Circle, Text, Transformer } from 'react-konva';


export interface AnnotationType {
    x: number;
    y: number;
    width: number;
    height: number;
    label: string;
    type: 'rect' | 'circle'
    rectId: string;
    id: string;
    qualified: boolean,
    error?: string
};

const Qualified = (qualify: boolean) => {
    return qualify ? '#29CB66' : '#EE1A10'
};


const LabelSyntheticReCircle = ({
    shapeProps,
    onSelect,
    isSelected,
    statusColor,
    dragEnable,
    onCircle,
    onChange,
    showLabel,
    handleContext,
}: {
    shapeProps: AnnotationType,
    isSelected: boolean,
    statusColor: string;
    onCircle: boolean,
    dragEnable: boolean;
    onSelect: () => void,
    onChange: (newAnn: AnnotationType) => void,
    showLabel?: boolean,
    handleContext?: (e: KonvaEventObject<PointerEvent>) => void
}) => {
    const { x, y, width, height, id } = shapeProps;
    const radius = Math.max(5, width > height ? width : height);

    const circleRef = React.useRef<any>();
    const trRef = React.useRef<any>();

    React.useEffect(() => {
        if (isSelected && trRef && trRef.current) {
            trRef.current.nodes([circleRef.current]);
            trRef.current.getLayer().batchDraw();
        }

    }, [isSelected]);

    return (
        <React.Fragment>
            {showLabel && <Text
                x={x}
                y={(y - radius - 24) < 0 ? (y - radius) : (y - radius - 24)}
                text={shapeProps.label}
                fontSize={24}
                align={'center'}

                fill={'#fff'}
                stroke={Qualified(shapeProps.qualified)}
            />}
            <Circle
                id={id}

                onContextMenu={(e) => handleContext && dragEnable && handleContext(e)}
                onClick={onSelect}
                onTap={onSelect}
                ref={circleRef}
                x={x}
                y={y}
                radius={radius}
                draggable={dragEnable}
                stroke={Qualified(shapeProps.qualified)}

                fill="transparent"
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        x: e.target.x(),
                        y: e.target.y()
                    });
                }}
                onTransformEnd={(e) => {
                    const node = circleRef.current;
                    const r = node.radius() * node.scaleX()
                    node.scaleX(1);
                    node.scaleY(1);
                    onChange({
                        ...shapeProps,
                        x: node.x(),
                        y: node.y(),
                        width: Math.max(5, r),
                        height: Math.max(5, r)
                    });
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    flipEnabled={false}
                    enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
                    rotateEnabled={false}
                    boundBoxFunc={(oldBox, newBox) => {
                        if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </React.Fragment>
    )
}

export default LabelSyntheticReCircle