import { ActionTypes } from "../action-types";
import * as Actions from "../actions";
import { Classification } from "./datasets";

export interface ClassSummary {
  class: number;
  classlabel: string;
  count: number;
  imagecount: number;
};
export interface LabelSummary {
  totalCount: number;
  noLabeled: string;
  hasLabeled: string;
}
export interface DatasetImageJson {
  labelindex: number,
  id: number;
  type: string;
  url: string;
  filename: string;
  sourceOrigin: string;
  import_at: string;
  labeled: boolean;
  datasetDetectLabels?: LabelElement[];
}

export interface DetectLabelElement {
  id: number;
  topLeftX: number;
  topLeftY: number;
  bottomRightX: number;
  bottomRightY: number;
  offsetX?: number;
  offsetY?: number;
  position?: string;
  classificationId?: number;
  isImport?: boolean;
}

export type LabelElement = DetectLabelElement;

export interface OnGoingLabelState {
  isOnGoingLabelModelOpen: boolean;
  onGoingDatasetId: number | null;
  onGoingDatasetType: string;
  onGoingDatasetName: string;
  onGoingClassifications: Classification[];
  imagesCount: number;
  currentIndex: number;
  isDatasetImagesQueryCountPending: boolean;
  currentImage: null | DatasetImageJson;
  currentLabelElements: LabelElement[];
  currentHoverElementIndex: number;
  isImageLoading: boolean;
  isLabelUpdating: boolean;
  isImageDeleting: boolean;
  currentLabelId: number | null;
  currentDatasetId: number | null;
  classSummary: ClassSummary[];
  labelSummary: LabelSummary[];
  fromExternal: number;
  isLabelLastSave: number;
  isLabelUpdateEmpty: number;
  refresh: number;
  autoLabeling: boolean;
}

const INIT_STATE: OnGoingLabelState = {
  isOnGoingLabelModelOpen: false,
  onGoingDatasetId: null,
  onGoingDatasetType: "",
  onGoingDatasetName: "",
  onGoingClassifications: [],
  imagesCount: 0,
  currentIndex: 0,
  isDatasetImagesQueryCountPending: false,
  currentImage: null,
  currentLabelElements: [],
  currentHoverElementIndex: 9999,
  isImageLoading: false,
  isLabelUpdating: false,
  isImageDeleting: false,
  currentLabelId: null,
  currentDatasetId: null,
  classSummary: [],
  labelSummary: [],
  fromExternal: 0,
  isLabelLastSave: 0,
  isLabelUpdateEmpty: 0,
  refresh: 0,
  autoLabeling: false
};

export const reducer = (
  state = INIT_STATE,
  action: Actions.OnGoingLabelAction
) => {
  switch (action.type) {
    case ActionTypes.DATASET_IMAGE_NAVIGATE_TO:
      return {
        ...state,
        currentIndex: action.index,
      };
    case ActionTypes.TOGGLE_LABELING_MODEL:
      return {
        ...state,
        isOnGoingLabelModelOpen: !state.isOnGoingLabelModelOpen,
        refresh: Math.floor(Math.random() * 65535)
      };
      case ActionTypes.TOGGLE_LABELING_REFRESH:
        return {
          ...state,
          fromExternal: Math.floor(Math.random() * 65535),
          refresh: Math.floor(Math.random() * 65535)

        };      
    case ActionTypes.SET_ON_GOING_LABAL_DATASET:
      return {
        ...state,
        isOnGoingLabelModelOpen: true,
        onGoingDatasetId: action.datasetId,
        onGoingDatasetType: action.datasetType,
        onGoingDatasetName: action.datasetName,
        onGoingClassifications: action.classifications,
        currentIndex: 0,
        isImageLoading: false,
        fromExternal: 0,
        imagesCount: (action.imagesCount != undefined ? action.imagesCount : state.imagesCount)
        //refresh: 
      };
    case ActionTypes.SET_ON_GOING_LABAL_DATASET_NAVIGATE_TO:
      return {
        ...state,
        isOnGoingLabelModelOpen: true,
        onGoingDatasetId: action.datasetId,
        onGoingDatasetType: action.datasetType,
        onGoingDatasetName: action.datasetName,
        onGoingClassifications: action.classifications,
        currentIndex: action.index,
        isImageLoading: false,
        fromExternal: Math.floor(Math.random() * 65535)

      };
    case ActionTypes.DATASET_IMAGES_QUERYING_START:
      return {
        ...state,
        isDatasetImagesQueryCountPending: true,
      };
    case ActionTypes.DATASET_IMAGES_QUERYING_SUCCESS:
      return {
        ...state,
        isDatasetImagesQueryCountPending: false,
        imagesCount: action.imagesCount,
      };
    case ActionTypes.DATASET_IMAGES_QUERYING_ERROR:
      return {
        ...state,
        isDatasetImagesQueryCountPending: false,
      };
    case ActionTypes.DATASET_IMAGE_FETCHING_START:
      return {
        ...state,
        isImageLoading: true,
        currentImage: null,
        currentLabelElements: [],
        currentHoverElementIndex: 9999,
      };
    case ActionTypes.DATASET_IMAGE_FETCHING_SUCCESS:
      return {
        ...state,
        isImageLoading: false,
        currentImage: action.currentImage,
      };
    case ActionTypes.DATASET_IMAGE_FETCHING_ERROR:
      return {
        ...state,
        isImageLoading: false,
      };
    case ActionTypes.SET_CURRENT_LABEL_ELEMENTS:
      return {
        ...state,
        currentLabelElements: action.labelElements,
      };
    case ActionTypes.SET_CURRENT_LABEL_ID:
      return {
        ...state,
        currentLabelId: action.labelId,
      }
    case ActionTypes.SET_CURRENT_HOVER_LABEL_ELEMENT:
      return {
        ...state,
        currentHoverElementIndex: action.index,
      };
    case ActionTypes.DELETE_LABEL_ALL:
      return {
        ...state,
        currentLabelElements: []
      };
    case ActionTypes.SET_CLASS_SUMMARY:
      return {
        ...state,
        classSummary: action.classSummary
      };
    case ActionTypes.INIT_CLASS_SUMMARY:
      return {
        ...state,
        classSummary: []
      }
    case ActionTypes.SET_LABEL_SUMMARY:
      return {
        ...state,
        labelSummary: action.labelSummary
      };
    case ActionTypes.INIT_LABEL_SUMMARY:
      return {
        ...state,
        labelSummary: []
      };
    case ActionTypes.DELETE_LABEL_BY_INDEX:
      return {
        ...state,
        currentLabelElements: [...state.currentLabelElements].filter(
          (_, i) => i !== action.index
        ),
      };
    case ActionTypes.INSERT_DETECT_LABELS_TO_IMAGE_START:
      return {
        ...state,
        isLabelUpdating: true,
      };
    case ActionTypes.INSERT_DETECT_LABELS_TO_IMAGE_SUCESS:
      return {
        ...state,
        isLabelUpdating: false,
        isLabelLastSave: Math.floor(Math.random() * 65535),
        isLabelUpdateEmpty: (action.labelCount > 0) ? 0 : Math.floor(Math.random() * 65535),
      };
    case ActionTypes.INSERT_DETECT_LABELS_TO_IMAGE_FAIL:
      return {
        ...state,
        isLabelUpdating: false,
      };
    case ActionTypes.DELETE_DATASET_IMAGE_BY_ID_START:
      return {
        ...state,
        isImageDeleting: true,
      };
    case ActionTypes.DELETE_DATASET_IMAGE_BY_ID_SUCCESS:
      return {
        ...state,
        isImageDeleting: false,
        imagesCount: state.imagesCount - 1,
      };

    case ActionTypes.DELETE_DATASET_IMAGE_BY_ID_FAIL:
      return {
        ...state,
        isImageDeleting: false,
      };
    case ActionTypes.SET_AUTO_LABELING:
      return {
        ...state,
        autoLabeling: action.auto
      };
    case ActionTypes.SET_CURRENT_LABEL_CLASSIFICATION:
      return {
        ...state,
        currentLabelElements: state.currentLabelElements.map((ele, i) => {
          if (i === action.labelIndex) {
            return {
              ...ele,
              classificationId: action.classificationId,
            };
          }
          return ele;
        }),
      };
    case ActionTypes.INCREASE_DATASET_IMAGE_BY_IMAGE_COUNT:
      return {
        ...state,
        imagesCount: state.imagesCount +action.count,
      }
    default:
      return state;
  }
};

export default reducer;
