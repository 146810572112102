import { KonvaEventObject } from 'konva/lib/Node';
import React from 'react';
import { Rect, Text, Transformer } from 'react-konva';


export interface AnnotationType {
    x: number;
    y: number;
    width: number;
    height: number;
    type: 'rect' | 'circle'
    rectId: string;
    id: string;
    qualified: boolean;
    label: string;
    error?: string
};


const LabelSyntheticRectangle = ({
    shapeProps,
    isSelected,
    statusColor,
    dragEnable,
    onLabel,
    showLabel,
    onSelect,
    onChange,
    handleContext
}: {
    shapeProps: AnnotationType,
    isSelected: boolean,
    statusColor: string;
    onLabel: boolean,
    dragEnable: boolean,
    onSelect: () => void,
    showLabel?: boolean;
    onChange: (newAnn: AnnotationType) => void,
    handleContext?: (e: KonvaEventObject<PointerEvent>) => void
}) => {

    const { x, y, width, height, id } = shapeProps
    const rectRef = React.useRef<any>();
    const trRef = React.useRef<any>();

    React.useEffect(() => {
        if (isSelected && trRef && trRef.current) {
            trRef.current.nodes([rectRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);


    return (
        <React.Fragment>
            {showLabel && <Text
                x={x}
                y={y - 24}
                text={shapeProps.label}
                fontSize={24}
                fontFamily={'Calibri'}
                align={'center'}
                width={300}
                stroke={statusColor}
            />}
            <Rect
                id={id}
                onContextMenu={(e) => handleContext && dragEnable && handleContext(e)}
                onClick={onSelect}
                onTap={onSelect}
                ref={rectRef}
                x={x}
                y={y}
                width={width}
                height={height}
                draggable={dragEnable}
                stroke={statusColor}
                fill="transparent"
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        x: e.target.x(),
                        y: e.target.y(),
                    });
                }}
                onTransformEnd={(e) => {
                    const node = rectRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();
                    node.scaleX(1);
                    node.scaleY(1);
                    onChange({
                        ...shapeProps,
                        x: node.x(),
                        y: node.y(),
                        // set minimal value
                        width: Math.max(5, node.width() * scaleX),
                        height: Math.max(node.height() * scaleY),
                    });
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    flipEnabled={false}
                    rotateEnabled={false}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </React.Fragment>
    );
};

export default LabelSyntheticRectangle

