import { useEffect, useState } from 'react';

const useImageBase64 = (imageUrl: string | null) => {
    const [base64, setBase64] = useState<string | null>(null);

    useEffect(() => {
        const fetchBase64 = async () => {
            try {
                if (!imageUrl) return;

                const response = await fetch(imageUrl);
                const blob = await response.blob();
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    if (typeof reader.result === 'string') {
                        setBase64(reader.result);
                    }
                };
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        };

        fetchBase64();

        return () => {
            // Cleanup if component unmounts or if imageUrl changes
            setBase64(null);
        };
    }, [imageUrl]);

    return base64;
};

export default useImageBase64;